import React from "react";
import { LogoImage } from "@/data";
import { Icons } from "@/data";

const Footer = () => {
  const { small } = LogoImage;
  const { phone } = Icons;
  const { mail } = Icons;
  return (
    <footer className="footer_1">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6 col-md-4 border-style"> 
            <aside className="widget aboutwidget element-style black-text">
              <a href="/">
                <img src={small} alt="" className="margin-34"/>
              </a>
              <p className="black-text">
                Сүхбаатар дүүрэг 2-р хороо 
                <br />
                City center 2 давхар 
              </p>
            </aside>
          </div>
          <div className="col-lg-4 col-sm-4 col-md-4 border-style">
            <aside className="widget contact_widgets element-style black-text">
              <a href="/">
                <img src={phone} alt="" className="margin-34" />
              </a>
              <p className="black-text">
                Утас: 94110121
                <br />Утас: 94110121
              </p>
            </aside>
          </div>
          <div className="col-lg-4 col-sm-2 col-md-4 border-style">
            <aside className="widget social_widget element-style black-text">
              <a href="/">
                <img src={mail} alt="" className="margin-34"/>
              </a>
              <p className="black-text">
                Майл: info@e-stg.mn
              </p>
            </aside>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div className="copyright">
              © copyright {new Date().getFullYear()} by{" "}
              <a href="#">Btsoftllc.mn</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
